import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.css']
})
export class ToastrComponent implements OnInit {

  

  constructor() { }

  ngOnInit(): void {

   

  }


  popup( type:string ='success' , textcontent:string = '', timeout=5000){

    var showtext = "";
    

    if(type == 'success') showtext  = '<i class  = "fa fa-check-circle " style ="margin-right:10px; font-size: 23px"></i>';
    else if(type == 'info')  showtext  = '<i class  = "fa fa-info-circle" style ="margin-right:10px; font-size: 23px"></i>';
    else if(type == 'warning')  showtext  = '<i class  =  "fas fa-exclamation-triangle" style ="margin-right:10px; font-size: 23px"></i>';
    else if(type == 'error')  showtext  = '<i class  = "fas fa-exclamation-triangle" style ="margin-right:10px; font-size: 23px"></i>';

    // var html  = '<div class ="row"><div class="col-sm-2">'+showtext+'</div><div class  ="col-sm-10"><span>'+textcontent+'</span></div></div>'
    var html  = '<div class ="row"><div class="col-sm-12"><div class="float-left float-left d-flex align-items-center">'+showtext+' <span style="font-size:20px !important">Notification &nbsp;</span> </div><div class="float-right"><i class  = "fa fa-times close_popup" style ="margin-right:10px;font-size: 15px; cursor:pointer;"></i></div></div><div class  ="col-sm-12 mt-2"><span style="line-height: 23px;padding: 10px 15px 10px 36px; display:block;">'+textcontent+'</span></div></div>'
    // var html  = '<div class ="row"><div class  ="col-sm-12">'+showtext+'<span>'+textcontent+'</span></div></div>'

    $('#toastrBox').addClass(type);
    $('.textcontent').html(html);

    $('#toastrBox').animate({ width: "toggle" },'slow');
    $(".open-call").toggleClass("opened closed");

    setTimeout(function(){ 
      $("#toastrBox").removeClass(type).css('display','none');
    },timeout);

    $(".close_popup").on("click",function(){
      $("#toastrBox").removeClass(type).css('display','none');
    })

  }

}
