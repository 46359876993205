import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPointerAutoFocus]'
})
export class PointerAutoFocusDirective {

  constructor(public elRef:ElementRef) { }
   ngOnInit() {
    setTimeout(() => {
      this.elRef.nativeElement.focus();
    });
  }

}
