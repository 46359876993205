
import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders , HttpHandler , HttpErrorResponse ,HttpEvent} from '@angular/common/http';
import { Common } from './common';
import {  EMPTY, throwError, of, Observable } from 'rxjs';
import {Router} from '@angular/router'; 


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization': 'Bearer '+ localStorage.getItem('authToken'),
    })
  }

  constructor(private http: HttpClient , private common : Common ,private router:Router) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessage = error.error.errors;
     
      if(error.status === 404){
        $('.overlay').css('display','none');
        this.router.navigate(['../../**']);
      } 

      if(error.status === 429){
        $('.overlay').css('display','none');
      } 

    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public  getParms  = {};

  getMethodApiRequest(url:any){
    return this.http.get(url, this.httpOptions);
  }

  postMethodApiRequest (url:any, data:any ){
    return this.http.post(url, data, this.httpOptions);
  }

  putMethodApiRequest(url:string, data:any){
    return this.http.put<any>(url, data, this.httpOptions);
  }

  uploadFileMethodRequest(url:string, formData:any)
  {
     let httpHeaders = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer '+ localStorage.getItem('authToken')
        })
      }
   
    return this.http.post<any>(url, formData, httpHeaders);
  }

  downloadPdFPostApiRequest(url: any, data: any) {
    let headers = new HttpHeaders({
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
                  })
  
    return this.http.post(url, data, {headers, responseType: 'blob'});
  }

  

  getByIdMethodRequest(url: any, params: any)
  {
     let httpHeaders = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer '+ localStorage.getItem('authToken'),
          params
        })
      }
    return this.http.get<any>(url, httpHeaders);
  }
}